:root {
  --primary-color: #d2ae4d !important;
  --secondary-color-light: #d2ae4d;
  --secondary-color: #4c0d34 !important;
  --grey-color: #C3C8C9 !important;
  --grey-color-link: #787D7E;
  --light-color: #F0F2F3;
  --btn-primary: #d2ae4d !important;
  --btn-secondary: #4c0d34 !important;
}

$theme-colors: (
  "primary": #d2ae4d,
  "secondary-light": #d2ae4d,
  "secondary": #4c0d34,
  "grey": #C3C8C9,
  "grey-link": #787D7E,
  "grey-light": #A0A5A6,
  "light": #EBEDF0,
  "white": #FFFF
);